$success: #759e43;
$danger: #bb352c;
$warning: #ea940a;
$secondary: #00aae7;

$button-success-background-color: $success;
$button-success-color: #ffffff;
$button-default-background-color: #00aae7;
$button-default-color: #ffffff;

$default-font: "Open Sans", "Poppins", "Helvetica Neue", "Segoe UI", Helvetica, Verdana, sans-serif;
$default-font-size: 1em;
$default-color: #3c3c3b;

@import "../node_modules/bootstrap/scss/bootstrap";

// Overrida Devextreme defult font
.dx-widget {
  font-family: 'Open Sans', sans-serif !important;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: $default-font; //Roboto, "Helvetica Neue", sans-serif;
  color: $default-color;
  background-color: #f2f2f2;
  font-size: $default-font-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $default-color;
  font-family: $default-font;
  // font-size: $partner-font-size;
}

a:link,
a:visited {
  color: #3366cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.color-danger {
  color: $danger;
}

.color-success {
  color: $success;
}

.edit-link {
  font-size: 0.8em;
  color: #999999;
  // border-bottom: dashed 1px #cccccc;
  cursor: pointer;
}

.edit-link:hover {
  color: $default-color;
  border-bottom: dashed 1px #999999;
}

.default-link {
  color: #3366cc;
  cursor: pointer;
}

.main-container {
  padding-top: 20px;
  padding-bottom: 50px;
  border-left: #cccccc solid 1px;
  border-right: #cccccc solid 1px;
  background-color: #fff;
  min-height: 100%;
}

.app-logo {
  height: 60px;
  cursor: pointer;
}

#logout-button {
  border-color: #fff;
  color: #fff;
}

#logout-button .dx-icon {
  color: #fff;
}

#login-button {
  border-color: $success;
  color: #fff;
}

#login-button .dx-icon {
  color: #fff;
}

.page-back-link {
  font-size: 0.9em;
}

.row-to-delete {
  background-color: #cccccc;
}

.dx-button-mode-contained.dx-button-default,
.dx-button-default {
  background-color: $button-default-background-color;
  color: $button-default-color;
}

.dx-button-mode-contained.dx-button-default:hover,
.dx-button-default:hover {
  background-color: darken($button-default-background-color, 7);
  color: $button-default-color;
}

.dx-button-mode-contained.dx-button-success,
.dx-button-success {
  background-color: $button-success-background-color;
  color: $button-success-color;
}

.dx-button-mode-contained.dx-button-success:hover,
.dx-button-success:hover {
  background-color: darken($button-success-background-color, 7);
  color: $button-success-color;
}

.top-container {
  // background-image: url('./assets/images/leasing-agreements-navbar-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 180px;
  position: relative;
}

.logo-container {
  float: left;
  margin-left: 50px;
  display: flex;
  height: 100%;
  padding-top: 50px;
}

.current-module-container {
  font-family: Roboto, "Helvetica Neue", "Segoe UI", Calibri, sans-serif;
  font-size: 30px;
  letter-spacing: 2px;
  // height: 100px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 45%;
  margin: -50px 0 0 -50px;
  text-align: center;
}

.current-supplier-account-container {
  float: left;
  color: #fff;
  margin-left: 35px;
}

.language-buttons-container {
  color: #fff;
  margin-right: 30px;
  cursor: pointer;
  font-size: 0.9em;
}

.service-category-box {
  background-size: cover;
  background-position: center center;
  color: white;
  margin-bottom: 20px;
  margin-right: 60px;
  margin-left: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: relative;
  padding-top: 45%;

  border: solid 2px #fff;
  border-radius: 30px;
  box-shadow: 0px 10px 48px #949494;
}

.service-category-box:hover {
  box-shadow: 0px 10px 48px #2e2e2e;
}

.service-category-box-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service-category-box-content h4 {
  color: #fff;
}

.dx-toast-success {
  background-color: $success;
}

.dx-toast-warning {
  background-color: $warning;
}

.dx-toast-error {
  background-color: $danger;
}

.dx-toast-info {
  background-color: $secondary;
}

.dx-toast-success {
  background-color: $success;
}

.portify-spinner {
  color: $success;
}

.nav-bar-buttons-container {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
}

.nav-item a {
  // text-transform: uppercase;
  font-weight: 400;
  margin-right: 30px;
  text-decoration: none;
}

.nav-link.active {
  border-bottom: white 1px solid;
}


.navbar-toggler {
  color: #fff !important;
}

.navbar {
  border-top: #858d9a 1px solid;
  background: linear-gradient(to right, #19232d, #0a1117);
}

.navbar-text,
.navbar-brand,
.nav-link {
  color: #fff !important;
}

.dropdown-item.custom-dropdown-item {
  color: #003333;
}

.dropdown-item.custom-dropdown-item:hover,
.dropdown-item.custom-dropdown-item:focus {
  color: #ffffff;
  background-color: #003333;
}

.dropdown-item.custom-dropdown-item.active {
  color: #ffffff;
  background-color: #003333;
}



.dx-button-xxs {
  height: 18px;
  font-size: 7px;
}

.dx-button-xxs .dx-icon {
  font-size: 12px;
}

.dx-button-xxs .dx-button-content {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 2px;
  padding-right: 3px;
}

.dx-button-sm {
  height: 22px;
  font-size: 12px;
}

.dx-button-sm .dx-icon {
  font-size: 16px;
}

.dx-button-sm .dx-button-content {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

::ng-deep .grid-is-filtered .dx-icon {
  color: white;
}

::ng-deep .grid-is-filtered {
  background-color: #346799;
}

::ng-deep .grid-is-filtered .dx-icon:hover {
  color: white;
}

::ng-deep .grid-is-filtered:hover {
  background-color: #346799;
}

.lease-agreement-deprecation-section {
  padding: 10px;
  border: #cccccc solid 1px;
  border-radius: 15px;
  background-color: #f5f5f5;
  margin-bottom: 30px;
}

.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-input-wrapper input {
  font-size: 100px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  /* For IE8 and earlier */
}

.file-input-wrapper span {
  display: inline-block;
  background-color: #fff;
  color: #000;
  padding: 6px 20px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  cursor: pointer;
}

/* Style when a file is selected */
.file-input-wrapper input:focus + span {
  background-color: #fff;
  outline: 1px solid #000;
  /* Optional: Add an outline for better focus visibility */
}

.image-selected {
  border: solid 4px $success;
  margin: 10px;
}

.image-default {
  margin: 10px;
}

.image-container {
  position: relative;
  display: inline-block;
  margin: 5px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 0px;
  padding-bottom: 3px;
  padding-left: 9px;
  padding-right: 9px;
  background-color: $danger;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  border-radius: 50%;
}

/* Additional styling for the close icon on hover (optional) */
.close-icon:hover {
  background-color: darkred;
}

.error-text {
  font-size: 0.8em;
  color: red;
}

@media (max-width: 370px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .service-category-box {
    margin-bottom: 20px;
    margin-right: 0px;
    margin-left: 0px;
    border: solid 2px #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 20px #949494;
  }

  .logo-container {
    float: left;
    margin-left: 20px;
    display: flex;
    height: 100%;
    padding-top: 20px;
  }

  .top-container {
    // background-image: url('./assets/images/leasing-agreements-navbar-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100px;
    position: relative;
  }

  .current-module-container {
    font-family: Roboto, "Helvetica Neue", "Segoe UI", Calibri, sans-serif;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    margin: -20px 0 0 -50px;
    text-align: center;
  }

  .current-supplier-account-container {
    float: left;
    color: #fff;
    margin-left: 5px;
  }

  .app-logo {
    height: 40px;
    cursor: pointer;
  }
}

@media (max-width: 972px) {
  .service-category-box {
    margin-bottom: 20px;
    margin-right: 0px;
    margin-left: 0px;
    border: solid 2px #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 20px #949494;
  }
}
